
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      name: 'Header',
      props: ['theme'],
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data() {
        return {
          screenWidth: 0,
          arrowClicked: false,
          openedMenu: false,
          opacity: 1,
          display: 'none',
          currentLang: localStorage.getItem('locale') || "th",
        };
      },
      mounted() {
        window.addEventListener('resize', this.handleResize);
      },
      computed: {
        computedOpacityMenu: function(): number {
          return this.opacity;
        },
        computedDisplayMenu: function(): string {
          return this.display;
        },
      },
      methods: {
        setLocale(locale: string): void {
          this.locale = locale;
          localStorage.setItem('locale', locale);
          this.currentLang = locale;
          // this.showSecondLangBlock();
        },
        showSecondLangBlock(): void {
          this.isArrowClicked() ? (this.arrowClicked = false) : (this.arrowClicked = true);
        },
        isArrowClicked(): boolean {
          return this.arrowClicked;
        },
        openCloseMenu(): void {
          this.openedMenu ? (this.openedMenu = false) : (this.openedMenu = true);

          this.openedMenu ? document.body.classList.add('menuIsOpened') : document.body.classList.remove('menuIsOpened');

          this.opacity = 0;
          this.display = 'block';

          this.openedMenu ? setTimeout(() => (this.opacity = 1), 50) : setTimeout(() => (this.opacity = 0), 50);

          !this.openedMenu ? setTimeout(() => (this.display = ''), 500) : '';
        },
        handleResize(): void {
          this.screenWidth = window.innerWidth;

          this.openedMenu && this.screenWidth < 768 ? document.body.classList.add('menuIsOpened') : document.body.classList.remove('menuIsOpened');
        },
        leavemouse() {
          this.arrowClicked ? (this.arrowClicked = false) : '';
        },
      },
    }
);
