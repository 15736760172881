<template>
  <SEO/>
  <router-view/>
</template>

<script>
import {defineComponent} from "vue";
import SEO from '@/components/SEO.vue'

export default defineComponent({
  components: {SEO},
  name: "App"
});
</script>

<style>
@import "../src/assets/fonts/stylesheet.css";
@import "../src/assets/fonts/icomoon/style.css";
@import "../src/assets/css/jquery-ui.css";
@import "../src/assets/css/modal.css";
@import "../src/assets/css/animate.css";
@import "../src/assets/build/css/style.css";
/* @import "../src/assets/scss/siteblocks.scss";
@import "../src/assets/scss/responsive-lg.scss";
@import "../src/assets/scss/responsive-md.scss";
@import "../src/assets/scss/responsive-sd.scss";
@import "../src/assets/scss/responsive-sm.scss";
@import "../src/assets/scss/responsive-xs.scss"; */
@import "../src/assets/css/jquery.mCustomScrollbar.css";
@import "../src/assets/css/custom.css";
</style>
