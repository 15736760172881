
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      name: 'Footer',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data() {
        return {
          arrowClicked: false,
        };
      },
      methods: {
        setLocale(locale: string): void {
          this.locale = locale;
          localStorage.setItem('locale', locale);
          this.showSecondLangBlock();
        },
        showSecondLangBlock(): void {
          this.isArrowClicked()
          ? (this.arrowClicked = false)
          : (this.arrowClicked = true);
        },
        isArrowClicked(): boolean {
          return this.arrowClicked;
        },
        leavemouse() {
          this.arrowClicked ? (this.arrowClicked = false) : '';
        },
      },
    }
);
