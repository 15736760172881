<script>
import {defineComponent} from 'vue'
import {useHead} from '@vueuse/head'

export default defineComponent({
  name: "SEO",
  setup() {
    useHead({
      title: `มันนี่ สเปซ | ระบบรับชำระเงินออนไลน์ถูกที่สุดในประเทศไทย`,
      meta: [
        {
          name: `description`,
          content: `payment gateway,ระบบรับชำระเงินออนไลน์,จ่ายเงินออนไลน์,รับบัตรเครดิตออนไลน์,ขายของรับบัตรเครดิต,ร้านค้ารับบัตรเครดิต`
        },
        {
          name: `keywords`,
          content: `payment gateways, payment platform, บริการรับชำระด้วยบัตรเครดิตออนไลน์, ระบบจ่ายเงินออนไลน์, ระบบรับชำระเงิน, ระบบชำระเงินออนไลน์, ร้านค้าออนไลน์รับบัตรเครดิต, ร้านค้ารับบัตรเครดิต, Payment facilitators, Payment facilitator, payment gateway, ชำระเงินผ่านบัตรเครดิต, ขายของรับบัตรเครดิต, รับบัตรเครดิตออนไลน์, จ่ายเงินออนไลน์`,
        },
        {
          property: `fb:pages`,
          content: `2553096228248116`
        },
      ],
    })
  },
})
</script>
