
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      name: 'MainInfo',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
    }
);
